import React from 'react';
import YouTube from 'react-youtube';

import { SEO, Layout, Header, Footer, GridContainer, Container } from '../../components';
//
import cssModule from './index.module.scss';
import { getYoutubeID } from '../../utils/shared';
import Divder from '../../assets/svg/aircraft-divder-line.svg';

const options = {
  height: '100%',
  width: '100%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const SingleSection = ({ title, media = {}, paragraphs = [] }) => {
  return (
    <GridContainer tablet="auto/.6fr" justifyContent="center" className={cssModule.singleSection}>
      <h4>{title}</h4>

      <Divder className="margin-bottom-m" />

      {/*  show video or image */}
      {media && media.video ? (
        <div className="video-holder">
          <YouTube videoId={getYoutubeID(media.video)} opts={options} />
        </div>
      ) : (
        <img src={media.image} width="100%" className="video-holder" />
      )}

      {/* show text */}
      {paragraphs.length &&
        paragraphs.map((p, i) => (
          <p key={i} className="p-large">
            {p}
          </p>
        ))}
    </GridContainer>
  );
};

export default ({ pageContext: ctx }) => {
  const { heroSection, instructions, translationPath, metadata, i18n_lang: lang } = ctx;

  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header {...heroSection} lang={lang} translationPath={translationPath} />

      <section className={cssModule.equipment}>
        <Container>
          {instructions &&
            instructions.map(({ title, media, paragraphs }) => (
              <SingleSection key={title} paragraphs={paragraphs} title={title} media={media} />
            ))}
        </Container>
      </section>
      <Footer lang={lang} />
    </Layout>
  );
};
